import axios from "axios";

const getApiBaseUrl = (): string => {
  const endPoint = window.location.hostname;

  let wiseServerAPI;
  switch (endPoint) {
    case "customer.wisesystems.io":
      wiseServerAPI = "https://api.wisesys.info";
      break;
    case "customer.jp.wisesystems.io":
      wiseServerAPI = "https://api.jp.wisesys.info";
      break;
    case "customer.sg.wisesystems.io":
      wiseServerAPI = "https://api.sg.wisesys.info";
      break;
    case "customer-uat.sg.wisesystems.io":
      wiseServerAPI = "https://api-uat.sg.wisesys.info";
      break;
    case "customer.uk.wisesystems.io":
      wiseServerAPI = "https://api.uk.wisesys.info";
      break;
    case "customer.mena.wisesystems.io":
      wiseServerAPI = "https://api.mena.wisesys.info";
      break;
    case "customer.uk2.wisesystems.io":
      wiseServerAPI = "https://api.uk2.wisesys.info";
      break;
    case "customer.me.wisesystems.io":
      wiseServerAPI = "https://api.me.wisesys.info";
      break;
    case "customer.au.wisesystems.io":
      wiseServerAPI = "https://api.au.wisesys.info";
      break;
    case "customer.ce.wisesystems.io":
      wiseServerAPI = "https://api.ce.wisesys.info";
      break;
    case "customer.sa.wisesystems.io":
      wiseServerAPI = "https://api.sa.wisesys.info";
      break;
    case "customer.ca.wisesystems.io":
      wiseServerAPI = "https://api.ca.wisesys.info";
      break;
    case "customer.demo.wisesys.info":
      wiseServerAPI = "https://api.demo.wisesys.info";
      break;
    case "customer.uat.wisesys.info":
      wiseServerAPI = "https://api.uat.wisesys.info";
      break;
    case "customer.stage.wisesys.info":
      wiseServerAPI = "https://api.stage.wisesys.info";
      break;
    case "customer.sandbox.wisesys.info":
      wiseServerAPI = "https://api.sandbox.wisesys.info";
      break;
    default:
      wiseServerAPI = "http://localhost:8002";
  }

  return wiseServerAPI;
};

export const axiosInstance = axios.create({
  baseURL: getApiBaseUrl(),
});
